import { useState } from "react"
import config from "@root/config.js"

export type RecaptchaResponse = {
  success: boolean
  "error-codes": string[]
  challenge_ts: string
  hostname: string
  action: string
  cdata: string
  metadata: {
    interactive: boolean
  }
}

export const useTurnstile = () => {
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null)
  const [widgetId, setWidgetId] = useState<string | null>(null)
  const [turnstileError, setTurnstileError] = useState<string | null>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const turnstile_key = config.settings.cloudflare.turnstile_key

  return {
    turnstileError,
    isDisabled,
    turnstile_key,
    turnstileToken,
    widgetId,
    setIsDisabled,
    setTurnstileError,
    setTurnstileToken,
    setWidgetId,
  }
}

export const validateTurnstileToken = async (secret: string, token?: string) => {
  if (!token) {
    console.error("No token provided for captcha validation.")
    throw new Error("Captcha validation failed. Please refresh the page and try again.")
  }

  const verifyRes = await fetch(config.settings.cloudflare.turnstile_verify_url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      secret,
      response: token,
    }),
  })

  const verifyData = (await verifyRes.json()) as RecaptchaResponse

  if (verifyData?.success === false) {
    console.error("Captcha validation failed.", JSON.stringify(verifyData))
    throw new Error("Captcha validation failed. Please refresh the page and try again.")
  }
}
