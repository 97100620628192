import React, { useMemo } from "react"
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react"
import Icon from "@app/components/Icon"

type Props = {
  iconName: string
  text: string
  progress: number
} & BoxProps

export const CartProgressCard: React.FC<Props> = React.memo(({ iconName, text, progress, ...props }) => {
  const thresholdMet = useMemo(() => progress >= 100, [progress])

  return (
    <Box {...props}>
      <Box
        pt={2}
        pb={3}
        px={5}
        pos="relative"
        _before={{
          content: "''",
          bgColor: thresholdMet ? "preset.worldOrange" : "preset.worldBlue",
          borderRadius: "8px",
          pos: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.15,
          transition: "0.2s background-color",
        }}
      >
        <Flex minH={5} gap={2} alignItems="center">
          <Icon name={iconName} width="14px" height="14px" fill="none" />
          <Text flex={1} variant="text12" py={1}>
            {text}
          </Text>
        </Flex>
        <Box borderRadius={2} h="2px" mt={2} pos="relative" overflow="hidden">
          <Box bgColor="#D2D2D4" w="100%" h="100%" pos="absolute" />
          <Box
            bgColor={thresholdMet ? "preset.worldOrange" : "#494C55"}
            borderRightRadius={thresholdMet ? 0 : 2}
            w={`${progress}%`}
            h="100%"
            pos="absolute"
            transition="0.2s width, 0.2s background-color"
          />
        </Box>
      </Box>
    </Box>
  )
})
