import React, { memo, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, AspectRatio, Image, Text, Flex, Button } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

type Props = {
  product: any
  swiper?: boolean
}

export const GiftWithPurchaseProduct: React.FC<Props> = memo(({ product }) => {
  const { giftWithPurchase } = useStaticQuery<GatsbyTypes.StaticGWPQuery>(graphql`
    query StaticGWP {
      giftWithPurchase: sanitySettingGwp {
        additionalGift
      }
    }
  `)
  const { loading } = useCart()
  const { addGwp } = useGiftWithPurchase()

  const productImage = useMemo(() => product?.images?.[0], [product?.images])
  const defaultVariant = useMemo(() => product?.variants?.[0], [product?.variants])

  const addToCartHandler = useCallback(async () => {
    const variant = defaultVariant
    if (!variant) return
    addGwp(product, defaultVariant)
  }, [addGwp, defaultVariant, product])

  return (
    <Flex alignItems="center">
      <Box maxWidth={{ base: "56px", sm: "60px" }} width="full" mr="12px">
        <AspectRatio ratio={56 / 71}>
          <Image src={productImage?.originalSrc} alt={productImage?.altText || ""} loading="lazy" />
        </AspectRatio>
      </Box>
      <Box ml="15px" width="full">
        <Flex justifyContent="space-between" alignItems="center">
          <Box textAlign="left">
            {product?.title && (
              <Text fontSize={{ base: "14px", md: "16px" }} mb="16px" fontWeight={500}>
                {product.title}
              </Text>
            )}
            <Text fontSize={{ base: "14px", md: "15px" }} fontWeight={500} color="brand.red">
              {giftWithPurchase?.additionalGift}
            </Text>
          </Box>
          <Flex justifyContent={{ base: "center", sm: "space-between" }} alignItems={"center"} flexDir={"column"}>
            <Button
              onClick={addToCartHandler}
              isLoading={loading}
              variant="fillRainbowLoader"
              minWidth="70px"
              fontSize="12px"
              lineHeight="14px"
              textAlign="center"
            >
              {loading ? "Adding" : `Add`}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
})
