import React, { useCallback, useEffect, useRef } from "react"
import { Box, Center, IconButton } from "@chakra-ui/react"
import Icon from "@app/components/Icon"

import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { CartAbandonedWidget } from "@app/components/Cart/CartAbandonedWidget"

type Props = {
  light: boolean
}

export const CartWidget: React.FC<Props> = React.memo(({ light }) => {
  const { count } = useCartContext()
  const { state, dispatch } = useAppContext()

  const handleActiveCart = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch({
        type: "cart",
        payload: !state.activeCart,
      })
    },
    [dispatch, state.activeCart]
  )

  useEffect(() => {
    if (state.activeCart) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [state.activeCart])

  const buttonRef = useRef()

  return (
    <Box pos="relative">
      <Box pos="relative" _hover={{ color: "primary.greyDisabled" }} transition="all .4s ease-in-out" ref={buttonRef}>
        {count > 0 && (
          <Center
            zIndex={10}
            position="absolute"
            top={1.25}
            right={2}
            bgColor="preset.worldOrange"
            borderRadius="50%"
            fontWeight={700}
            fontSize="xs"
            width={4}
            height={4}
            pointerEvents="none"
          >
            {count}
          </Center>
        )}
        <IconButton
          variant="headerIcon"
          fill={light ? "preset.default" : "preset.white"}
          color={light ? "preset.white" : "preset.default"}
          title={`Open cart (${count})`}
          aria-label={`Open cart (${count})`}
          icon={<Icon name="cart" width={12} height={12} p={3.5} />}
          onClick={handleActiveCart}
        />
      </Box>
      <CartAbandonedWidget />
    </Box>
  )
})
