module.exports = {
  id: "allkindsstaging",
  title: "Allkinds",
  description:
    "Say hello to awesome: all kinds of bath, body, haircare, skincare & fun stuff made just for kids. And with zero gross stuff.",
  url: "staging.allkinds.com",
  subfolder: "",
  locationCurrencyCode: "AUD",
  locationRegion: "AU",
  locationRegionFullName: "Australian",
  locationCountries: "AU,NZ",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/?key=Di68RWc6asPYru3MCgBD5bAZIMWaQwIFirSSKxUNjtwx9xDVnU",

  shopifyPlus: false,
  shopifyApiVersion: "2025-01",
  shopifyShopName: "allkinds-shop",
  shopifyShopDomain: "allkinds-shop.myshopify.com",
  shopifyCheckoutUrl: "checkout.staging.allkinds.com",
  shopifyStorefrontToken: "ff9d3174b85c6c28b27be458d5391c71",
  shopifyMetafieldIdStoreSync: "16222736580770",
  minimumOrderValue: 0,

  googleSheetsSpreadsheetId: "1VhEb12HgZJ9bOY2GIZ1Xaa9ebj6z4WuZWOXPVIlvvds",
  googleSheetsSheetId: "Staging",
  giftCardType: "Gift Card",

  sanityProjectId: "3oef59p4",
  sanityDataset: "staging",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skBoBI9fgEuAQxyYIWmwR1UaOXiCCkSLD69ce0lIIQazPjGb81Kb87Am9yIo8thtIFJjcLMY6IPwkU3DqlpPg98JkQ75CbhNfrKTvMRZi3dVbaKhAK2g6IkIxu5uzOlsaycOiBKBQIb39QN7yttUYYA12yGL31xhdYQ9OVNwRr2vjfA41fMJ",

  emarsysSignupFormID: "2419",
  emarsysCompetitionFormID: "2872",
  emarsysNewsletterFormID: "1464",

  emarsysAnalyticsId: "",
  tolstoyAppKey: "321b64bb-f483-4f59-8dc1-103ecf11d1b4",

  mapboxAccessToken: "pk.eyJ1IjoiYnJhbmRiYW5rIiwiYSI6ImNsOTNkYnVsOTAxZmozdnIyNDN0ZjhzanEifQ.rRjLiL_nVNUjPcGxkSfHKA",
  stockBrand: "AKAU",
  stockServiceUrl: "https://devapi.brandbank.com.au/inventory/v2/findinstore",
  flowProxyEndpoint: "https://devapi.brandbank.com.au",
  givexBrand: "allkinds-au",
  givexEndpoint: "https://givex-giftcards-staging.azurewebsites.net",

  functionAppDomain: "allkinds-au-functions-staging.fly.dev",

  wishlistPlusStorefrontEndpoint: "https://swymstore-v3premium-01.swymrelay.com/api/",
  wishlistPlusAdminEndpoint: "https://swymstore-v3premium-01.swymrelay.com/storeadmin/v3/user/",
  wishlistPlusApiKey: "gyoCZ8aVG7pHtdotPNtcUN3bdeZ4drP-yHsRPK2VFGpgVUAjcZk8m1oAoho_rhOGhZI917K4Fi-PFiLwZRKnAA",
  wishlistPlusPid: "lYrJt0ykjQe7HZz224SVbiIvHqdQZIbWKpCcB5j4GSI=",
  yotpoAppKey: "kt4oD4Qg0weICKK2KBjsnivgdeuq3gnk4GiwswQQ",
}
