module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@9.17.0__encoding_870d04dad564558a183151f4796f3da6/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Allkinds","short_name":"Allkinds","description":"Say hello to awesome: all kinds of bath, body, haircare, skincare & fun stuff made just for kids. And with zero gross stuff.","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"static/images/icon.png","icons":[{"src":"static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79f711f440d9ca6a12a51ef8bb2ad377"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-loadable-components-ssr@4.3.2_@babel+core@7.26.0_@loadable+component@5.16_85b4465f56601b243921aa896ce273e1/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@9.17.0__encod_9e7be8ea910470eedd74d786c77e1325/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_eslint@9.17.0__encoding@0.1.13_react-dom@18.3.1_react_63fd285f92105d5ef797216692011523/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
