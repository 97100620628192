import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

export const useMeta = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { helpers } = useCore()
  const { global, languages, organisation, tracking, scripts } = useStaticQuery<GatsbyTypes.StaticMetaQuery>(graphql`
    query StaticMeta {
      global: sanityTemplateGlobal {
        title
        description
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      organisation: sanitySettingOrganisation {
        title
        alternateTitle
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        og_title
        og_desc
        url
      }
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerify
        googleTagManagerId
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
    }
  `)

  const social: Array<any> = []
  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    helpers.schemaData({ breadcrumbs, data, global, language, organisation, routes, social, template, tracking, url }, mapping)

  const getTags = (data: any) =>
    helpers
      .metaTags(data)
      .map(tag =>
        tag.property === "og:title" && organisation?.og_title
          ? { ...tag, content: organisation?.og_title }
          : tag.property === "og:description" && organisation?.og_desc
            ? { ...tag, content: organisation?.og_desc }
            : tag
      )

  const getLanguages = (route: any) =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ""}`,
      hrefLang: `${region ? `${language}-${region}` : `${language}`}`,
      language: `${region ? `${language}-${region}` : `${language}`}`,
      rel: "alternate",
    }))

  const storeHelper = (data: any) => {
    if (data?.type !== "store") return null

    return {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: data?.document?.title || organisation?.title,
      address: {
        "@type": "PostalAddress",
        streetAddress: data?.document?.addressStreet,
        addressLocality: data?.document?.addressCity,
        addressRegion: data?.document?.addressState,
        postalCode: data?.document?.addressPostCode,
        addressCountry: data?.document?.addressCountry,
      },
      telephone: data?.document?.phone,
      url: organisation?.url,
    }
  }

  const getSchemas = (data: any) =>
    [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data), storeHelper(data)]
      ?.filter(schema => schema)
      .filter(schema => schema["@type"] !== "Organization" || data?.pageUrl === `${data.siteURL}${routes.HOMEPAGE}`)

  const getTracking = () =>
    helpers?.isDomReady
      ? [
          helpers.renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking-head", placement: "head" }),
          helpers.renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking-body", placement: "body" }),
          helpers.renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking-foot", placement: "foot" }),
        ]
      : []

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
