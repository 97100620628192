import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  Box,
  Button,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

import config from "@root/config.js"

import Icon from "@app/components/Icon"

import Link from "@app/components/Link"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "@app/providers/cart"
import { useAnalytics } from "@app/hooks/useAnalytics"
import MotionBox from "../MotionBox"
import { useCart } from "@app/hooks/useCart"
import { useMedia } from "@app/hooks/useMedia"

export const CartAbandonedWidget: React.FC = () => {
  const cartData = useStaticQuery<GatsbyTypes.StaticCartPopupQuery>(graphql`
    query StaticCartPopup {
      cart: sanityPageCart {
        activeCartReminderEnabled
        activeCartReminderTitle
        activeCartReminderSubtitle
        activeCartReminderButtonLabel
        activeCartReminderOosButtonLabel
      }
    }
  `)
  const { cart } = useCartContext()
  const { unavailableItems, isBelowMinOrder } = useCart()
  const { isXSmall, isHydrated } = useMedia()

  const { trackActiveCartClose, trackActiveCartCheckout } = useAnalytics()

  const [isActive, setIsActive] = useState(false)
  const initialRef = useRef(null)

  const hasAvailable = useMemo(() => cart?.lines?.some(line => line.merchandise.availableForSale), [cart?.lines])
  const displayPopup = useMemo(() => {
    return cart?.lines?.length && hasAvailable && cartData.cart?.activeCartReminderEnabled
  }, [cart?.lines, hasAvailable, cartData.cart?.activeCartReminderEnabled])
  const isCartInitialised = !!cart

  const isHideCartReminder = useCallback(() => {
    if (typeof window === "undefined" || !window.document) {
      return true
    }

    return (
      (document.cookie
        .split("; ")
        .find(row => row.startsWith(`${config.settings.keys.hideCartReminder}=`))
        ?.split("=")[1] || "false") === "true"
    )
  }, [])

  const setHideCartReminder = useCallback(() => {
    document.cookie = `${config.settings.keys.hideCartReminder}=true; Path=/; SameSite=Lax`
  }, [])

  // handle session storage and popup display
  useEffect(() => {
    ;(async () => {
      if (!isCartInitialised) return

      // Check cookie to determine if cart reminder popup should be displayed
      const hideCartReminder = isHideCartReminder()

      if (hideCartReminder) return

      // Display popup if:
      //  - cart contains atleast 1 in-stock item
      //  - cart reminder is enabled (within Sanity)
      if (displayPopup) setIsActive(true)
      // Else, set cookie to hide cart reminder popup, for subsequent visits within the same browser session
      else setHideCartReminder()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartInitialised])

  const cartContainsSoldoutItems = unavailableItems.length > 0
  const goToCart = cartContainsSoldoutItems || isBelowMinOrder
  const buttonLabel = !goToCart ? cartData.cart?.activeCartReminderButtonLabel : cartData.cart?.activeCartReminderOosButtonLabel
  const buttonUrl = !goToCart ? cart?.checkoutUrl : config.settings.routes.CART

  const handleClick = () => {
    if (cart?.lines.length) trackActiveCartCheckout(cart?.lines)
    // Set cookie to hide cart reminder popup, for subsequent visits within the same browser session
    setHideCartReminder()
    setIsActive(false)
  }

  const handleClose = () => {
    setIsActive(false)
    // Set cookie to hide cart reminder popup, for subsequent visits within the same browser session
    trackActiveCartClose(cart?.lines)
    setHideCartReminder()
  }

  return (
    <>
      {isHydrated && isXSmall && (
        <>
          {isActive && (
            <Box
              position="fixed"
              zIndex="overlay"
              top={0}
              left={0}
              width="100dvw"
              height="100dvh"
              background="rgba(30, 40, 40, 0.3)"
              onClick={handleClose}
              cursor="pointer"
            />
          )}

          <Box position="absolute" top="100%" right={0} zIndex="modal">
            {isActive && (
              <MotionBox
                style={{ width: "390px" }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2 }}
              >
                <Box
                  ml="auto"
                  mt={5}
                  mr={2}
                  background="white"
                  maxWidth={["none", "sm"]}
                  width="full"
                  marginBottom={0}
                  padding={8}
                  borderRadius={6}
                  textAlign="center"
                  position="relative"
                >
                  <IconButton
                    icon={<Icon name="closeAlt" width={12} height={12} />}
                    width={12}
                    height={12}
                    position="absolute"
                    top={0}
                    right={0}
                    variant="ghost"
                    onClick={handleClose}
                    aria-label="Close"
                    color="inherit"
                  />
                  <Heading paddingBottom={4} fontSize={24} fontWeight="bold" lineHeight="28px" color="primary.text">
                    {cartData.cart?.activeCartReminderTitle}
                  </Heading>
                  <Text fontWeight="500" lineHeight="24px" color="primary.text">
                    {cartData.cart?.activeCartReminderSubtitle}
                  </Text>
                  <Box padding={0} marginTop={6}>
                    <Button as={Link} to={buttonUrl} ref={initialRef} variant="fill" width="100%" onClick={handleClick}>
                      {buttonLabel}
                    </Button>
                  </Box>
                </Box>
              </MotionBox>
            )}
          </Box>
        </>
      )}
      {isHydrated && !isXSmall && (
        <Modal initialFocusRef={initialRef} isOpen={isActive} onClose={handleClose} size="sm" trapFocus={false}>
          <ModalOverlay background="rgba(30, 40, 40, 0.3)" backdropFilter="none" />

          <ModalContent
            position="fixed"
            bottom={4}
            left={4}
            right={4}
            width="auto"
            maxWidth="none"
            marginBottom={0}
            padding={8}
            borderRadius={6}
            textAlign="center"
            color="grey.text"
          >
            <IconButton
              icon={<Icon name="closeAlt" width={12} height={12} />}
              width={12}
              height={12}
              position="absolute"
              top={0}
              right={0}
              variant="ghost"
              onClick={handleClose}
              aria-label="Close"
              color="inherit"
            />
            <ModalHeader marginBottom={4} padding={0} fontSize={24} fontWeight="bold" lineHeight="28px" color="primary.text">
              {cartData.cart?.activeCartReminderTitle}
            </ModalHeader>
            <ModalBody fontWeight="500" lineHeight="24px" padding={0} color="primary.text">
              {cartData.cart?.activeCartReminderSubtitle}
            </ModalBody>
            <ModalFooter padding={0} marginTop={6}>
              <Button as={Link} to={buttonUrl} ref={initialRef} variant="fill" width="100%" onClick={handleClick}>
                {buttonLabel}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
