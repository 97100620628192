import React, { useRef, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useMedia } from "@app/hooks/useMedia"
import { useNewsletter } from "@app/hooks/useNewsletter"
import { useCustomerDetails } from "@app/hooks/useCustomer"

import Stripes from "@app/components/Stripes"
import SubscribeFormDetailed from "@app/components/Subscribe/SubscribeFormDetailed"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const Subscribe: React.FC<Props> = ({ location }) => {
  const headSize = useBreakpointValue<string>({ base: "heading20", sm: "heading24" })
  const headWidth = useBreakpointValue({ base: "250px", sm: "100%" })
  const { activeSubscribe, newsletter, handleClose } = useNewsletter(location)
  const initialRef = useRef(null)
  const title = newsletter?.additionalTitle
  const paragraph = newsletter?.additionalParagraph
  const { isBase } = useMedia()
  const {
    state: { geolocationOpen },
  } = useAppContext()
  const { customer } = useCustomerDetails()
  const [success, setSuccess] = useState(false)

  return (
    !customer?.acceptsMarketing && (
      <Modal
        onClose={handleClose}
        isOpen={activeSubscribe && !geolocationOpen}
        initialFocusRef={initialRef}
        motionPreset="slideInBottom"
        scrollBehavior={"outside"}
        isCentered={success ? true : false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0} maxW={["90%", "432px"]}>
          <ModalCloseButton margin="5px" />
          <ModalBody p={0} bgColor="brand.offWhite">
            <SimpleGrid columns={[1, 1]} border="8px solid transparent">
              <Flex px={5} py={8} flexDir="column" justifyContent="center" alignItems="center" textAlign="center">
                {!success && (
                  <>
                    <Heading as="p" variant={headSize} maxW={headWidth} lineHeight="28px" letterSpacing="0.03em" fontWeight="bold">
                      {title}
                    </Heading>
                    <Text maxW="300px" variant="text15" fontSize={20} mt={2} mb={4}>
                      {paragraph}
                    </Text>
                  </>
                )}
                <SubscribeFormDetailed {...{ success, setSuccess, headSize }} w="half" location={location} />
              </Flex>
            </SimpleGrid>
            {isBase && <Stripes />}
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  )
}

export default React.memo(Subscribe)
