import React from "react"
import { Text } from "@chakra-ui/react"

type Props = {
  message: string
}

const AccountLoginError: React.FC<Props> = ({ message }) => (
  <Text mt="10px" textAlign="center" lineHeight="24px">
    ⚠️ {message?.toString()}
  </Text>
)

export default React.memo(AccountLoginError)
