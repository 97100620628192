import { useCallback, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useStorage } from "@app/hooks/useCore"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"

import type { Location } from "@root/types/global"

export const useNewsletter = (location: Location) => {
  const { state, dispatch } = useAppContext()
  const {
    settings: { keys, routes },
    store: { subfolder },
  } = useConfigContext()
  const { getStorage, setStorage } = useStorage()

  const { newsletter } = useStaticQuery<GatsbyTypes.StaticNewsletterQuery>(graphql`
    query StaticNewsletter {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
        additionalTitle
        additionalParagraph
        additionalSuccess
        additionalSuccess1
        TCtitle
        TClink {
          ...LinkFragment
        }
      }
    }
  `)

  const route =
    Object.entries(routes)?.find(
      ([route, url]) => !route?.toLowerCase()?.includes("page") && url && location?.pathname?.includes(url)
    )?.[0] ||
    (location?.pathname?.replace(`${subfolder}`, "") === routes.HOMEPAGE && "HOMEPAGE")

  const active =
    // @ts-ignore
    (newsletter?.enabled && route && newsletter[`template${route?.charAt(0)?.toUpperCase() + route?.slice(1)?.toLowerCase()}`]) || false

  const handleActiveSubscribe = useCallback(
    (state: boolean) => {
      dispatch({
        type: "subscribe",
        payload: state,
      })
    },
    [dispatch]
  )

  const handleClose = useCallback(() => handleActiveSubscribe(false), [handleActiveSubscribe])
  const handleOpen = useCallback(() => handleActiveSubscribe(true), [handleActiveSubscribe])

  useEffect(() => {
    let timeout: any = null

    if (active && !getStorage(keys.newsletter) && !state.activeSubscribe) {
      timeout = setTimeout(
        () => {
          setStorage(keys.newsletter, true, newsletter?.expiry)
          handleActiveSubscribe(true)
        },
        (newsletter?.delay || 0) * 1000
      )
    }

    return () => (timeout ? clearTimeout(timeout) : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return {
    newsletter,
    handleClose,
    handleOpen,
    activeSubscribe: state.activeSubscribe,
  }
}
