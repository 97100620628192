import React, { useCallback, useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AspectRatio, Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useCartItemComputed } from "@app/hooks/useCartItemComputed"
import { useDebounce } from "@app/hooks/useDebounce"
import { useShopify, useShopifyPrice } from "@app/hooks/useShopify"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useBundle } from "@app/hooks/useBundle"
import Link from "@app/components/Link"
import Icon from "@app/components/Icon"
import ResolvedContent from "@app/components/ResolvedContent"
import Discount from "@app/components/Discount"
import CartItemBundle from "@app/components/Cart/CartItemBundle"
import { CartLine, ComponentizableCartLine } from "@shopify/hydrogen-react/storefront-api-types"

type Props = {
  item: CartLine | ComponentizableCartLine
}

export const CartItem: React.FC<Props> = ({ item }) => {
  const { giftWithPurchase, cart: cartTemplate } = useStaticQuery<GatsbyTypes.StaticCartItemQuery>(graphql`
    query StaticCartItem {
      giftWithPurchase: sanitySettingGwp {
        additionalGift
      }
      cart: sanityPageCart {
        additionalUnavailableLabel
      }
    }
  `)

  const {
    settings: { cart, routes, params, product },
  } = useConfigContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const [quantity, setQuantity] = useState<number>(Number(item.quantity))
  const { recipientName, recipientEmail, recipientDeliveryDate, recipientMessage } = useCartItemComputed(item)
  const { loading, removeFromCart, updateQuantity, giftWrapping } = useCart()
  const { formattedPrice, onSale: productOnSale, compareAtPrice } = useShopifyPrice(item.merchandise, item.quantity)
  const { urlResolver } = useRoutes()
  const { formatMoney } = useShopify()

  const { bundlePriceDiscounted, bundlePriceFull, isBundle, isOnSale } = useBundle(item?.merchandise?.product, quantity)

  useEffect(() => {
    // Reset quantity if it changes in the cart
    if (!loading) setQuantity(Number(item.quantity))
  }, [item.quantity, loading])

  const isDiscount = Number(item.cost.subtotalAmount.amount) > Number(item.cost.totalAmount.amount)
  const onSale = useMemo(() => productOnSale || isDiscount, [productOnSale, isDiscount])

  const isGiftWrap = useMemo(
    () => item.attributes?.some(({ key }: { key: string }) => key === cart.giftWrapAttribute),
    [item.attributes, cart.giftWrapAttribute]
  )
  const isGwp = useMemo(
    () => item.attributes?.some(({ key }: { key: string }) => key === cart.gwpAttribute),
    [item.attributes, cart.gwpAttribute]
  )

  const isOutOfStock = useMemo(() => !item?.merchandise?.availableForSale, [item?.merchandise?.availableForSale])

  const isGiftcard = useMemo(
    () => item.merchandise?.product?.handle?.toLowerCase() === product.giftcardHandle,
    [item.merchandise?.product?.handle, product.giftcardHandle]
  )
  const additionalTextAttribute = item.attributes?.find(attribute => attribute.key === cart.giftUpsellText)
  const additionalTextColorAttribute = item.attributes?.find(attribute => attribute.key === cart.giftUpsellTextColor)

  const handleQuantity = useCallback((quantity: number) => setQuantity(quantity), [setQuantity])

  const url = useMemo(
    () =>
      `${urlResolver(item.merchandise?.product, routes.PRODUCT)?.url}?${params.variant}=${decodeShopifyId(
        item.merchandise?.id,
        "ProductVariant"
      )}`,
    [decodeShopifyId, item, params.variant, routes, urlResolver]
  )

  const quantityAvailable = useMemo(() => {
    if (item.lineComponents) {
      // Get minimum quantity of all children which dictates how many of the bundle can be sold
      return item.lineComponents.reduce((min: number, component: CartLine) => {
        const quantityAvailable = component.merchandise?.quantityAvailable || 0
        if (min === -1 || quantityAvailable < min) {
          return quantityAvailable
        }
        return min
      }, -1)
    } else {
      // Not a bundle
      return item.merchandise?.quantityAvailable || 0
    }
  }, [item?.lineComponents, item.merchandise?.quantityAvailable])

  const isIncreaseQuantityDisabled = useMemo(() => {
    if (!quantity || loading) {
      return true
    }

    // Quantity available undefined, exclude when it is defined as 0
    if (!quantityAvailable && quantityAvailable !== 0) {
      return true
    }

    return quantity >= quantityAvailable
  }, [loading, quantity, quantityAvailable])

  useDebounce(
    () => {
      if (quantity !== Number(item.quantity)) {
        const action = quantity > item.quantity ? "add" : "remove"
        quantity ? updateQuantity(item.id, item.merchandise?.id, quantity, action) : removeFromCart(item.id, item.merchandise?.id)
      }
    },
    500,
    [quantity]
  )

  return item ? (
    <Flex pos="relative" w="100%" mt={4} pt={5}>
      <Box w="112px" flexShrink={0}>
        {item.merchandise?.image && (
          <Box as={!isGiftWrap ? Link : "unset"} to={!isGiftWrap ? url : "unset"}>
            <AspectRatio ratio={[112 / 140]}>
              <Image src={item.merchandise?.image?.originalSrc} w="100%" h="100%" objectFit="cover" />
            </AspectRatio>
          </Box>
        )}
      </Box>

      <Box flex={1} pl={2}>
        <Flex justifyContent="space-between" h="100%" flexDir="column" pl="10px">
          <Box pr={6}>
            <Text variant="text16" fontWeight={500}>
              <Box as={!isGiftWrap ? Link : "span"} to={!isGiftWrap ? url : "unset"} _hover={{ textDecoration: "none" }}>
                {item.merchandise.product.title}
              </Box>
            </Text>

            {isBundle && item.__typename === "ComponentizableCartLine" ? (
              <CartItemBundle lineComponents={item?.lineComponents} />
            ) : (
              item.merchandise?.title && (
                <Text mt={2} variant="text12">
                  {isGiftcard ? item.merchandise.title.split("/")[0].trim() : item.merchandise.title}
                </Text>
              )
            )}

            {additionalTextAttribute && (
              <Text mt={2} variant="text12" textTransform="uppercase" fontWeight={500} color={additionalTextColorAttribute?.value}>
                {additionalTextAttribute.value}
              </Text>
            )}

            {isOutOfStock && (
              <Text mt={2} variant="text12" color="preset.worldRed">
                {cartTemplate.additionalUnavailableLabel}
              </Text>
            )}

            {!isGwp && <Discount tags={item.merchandise.product?.tags} variant="text14" mt={1} />}

            {isGiftWrap && giftWrapping?.content && (
              <ResolvedContent
                mt={2}
                content={giftWrapping.content}
                color="brand.grayDisabled"
                sx={{
                  ".content-p": {
                    fontSize: "10px",
                    marginTop: "4px",
                  },
                }}
              />
            )}

            <Box mt={2}>
              {!!recipientName && (
                <Text variant="paragraph12" my={0} lineHeight="5" fontFamily="Larsseit">
                  Recipient Name: {recipientName}
                </Text>
              )}
              {!!recipientEmail && (
                <Text variant="paragraph12" my={0} lineHeight="5" fontFamily="Larsseit">
                  Recipient Email: {recipientEmail}
                </Text>
              )}
              {!!recipientDeliveryDate && (
                <Text variant="paragraph12" my={0} lineHeight="5" fontFamily="Larsseit">
                  Delivery Date: {recipientDeliveryDate}
                </Text>
              )}
              {!!recipientMessage && (
                <Text variant="paragraph12" my={0} lineHeight="5" fontFamily="Larsseit">
                  Gift Message: {recipientMessage}
                </Text>
              )}
            </Box>
          </Box>

          <Flex alignItems="center" justifyContent={["space-between", "flex-end"]} mt={4}>
            <Box>
              {!recipientName && !isGiftWrap && !isGwp && (
                <Flex alignItems="center" justifyContent="space-between" mr={[0, 4]}>
                  <Button
                    variant="cart"
                    isDisabled={!quantity || loading || isOutOfStock}
                    onClick={() => handleQuantity(quantity - 1)}
                    aria-label="Decrease Quantity"
                    title="Decrease Quantity"
                    px={2}
                  >
                    <Icon name="minus" width="8px" height="auto" />
                  </Button>
                  <Text textAlign="center" p={2} fontSize="12" bg="primary.greyLight" w={12} overflow="hidden">
                    {quantity}
                  </Text>
                  <Button
                    variant="cart"
                    isDisabled={!quantity || isIncreaseQuantityDisabled || loading || isOutOfStock}
                    onClick={() => handleQuantity(quantity + 1)}
                    aria-label="Increase Quantity"
                    title="Increase Quantity"
                    px={2}
                  >
                    <Icon name="plus" width="8px" height="auto" />
                  </Button>
                </Flex>
              )}
            </Box>
            <Flex alignItems="flex-end" justifyContent="center" gap={1.5} flexWrap="wrap">
              {isGwp || isBundle ? (
                isGwp ? (
                  <>
                    <Text variant="text15" fontWeight={500} textDecoration="line-through" mr={1.5}>
                      {formattedPrice}
                    </Text>
                    <Text variant="text15" fontWeight={500} color={"brand.red"}>
                      {giftWithPurchase?.additionalGift}
                    </Text>
                  </>
                ) : (
                  <>
                    {isOnSale ? (
                      <>
                        <Text variant="text15" fontWeight={500} textDecoration="line-through" color="brand.grayDisabled">
                          {bundlePriceFull}
                        </Text>
                        <Text variant="text15" fontWeight={500} color={"brand.red"}>
                          {bundlePriceDiscounted}
                        </Text>
                      </>
                    ) : (
                      <Text variant="text15" fontWeight={500} mr={1.5}>
                        {bundlePriceFull}
                      </Text>
                    )}
                  </>
                )
              ) : (
                <>
                  {onSale && (
                    <Text variant="text15" fontWeight={500} textDecoration="line-through" color="brand.grayDisabled">
                      {formatMoney(Math.max(compareAtPrice * item.quantity, Number(item.cost.subtotalAmount.amount)))}
                    </Text>
                  )}
                  <Text variant="text15" fontWeight={500} color={onSale ? "brand.red" : "brand.darkBlue"}>
                    {isDiscount ? formatMoney(item.cost.totalAmount.amount) : formattedPrice}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Button
          variant="cart"
          pos="absolute"
          top={0}
          right={-2}
          isDisabled={loading}
          onClick={() => removeFromCart(item.id, item.merchandise?.id)}
          title="Remove from bag"
        >
          <Icon name="closeSmall" width="18px" height="18px" />
        </Button>
      </Box>
    </Flex>
  ) : null
}
