import React from "react"
import { Button, Flex } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useCart } from "@app/hooks/useCart"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  isDisabled: boolean
}

export const CartCheckout: React.FC<Props> = React.memo(({ isDisabled }) => {
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartCheckoutQuery>(graphql`
    query StaticCartCheckout {
      cart: sanityPageCart {
        additionalRemoveUnavailableLabel
      }
    }
  `)

  const { linesCount, gotoCheckout, loading } = useCartContext()
  const { unavailableItems, removeOosFromCart, loading: cartActionLoading } = useCart()

  return linesCount > 0 ? (
    <Flex m="16px 20px">
      <Button
        fontWeight={500}
        lineHeight="15px"
        _disabled={{ bgColor: "#A4A5AA" }}
        variant="fillSmall"
        w="100%"
        height="unset"
        px="30px"
        py="26px"
        onClick={(e: MouseEvent) => {
          if (unavailableItems?.length) {
            removeOosFromCart()
            return
          }
          gotoCheckout(e)
        }}
        isLoading={loading || cartActionLoading}
        isDisabled={loading || isDisabled}
      >
        {unavailableItems?.length ? cart?.additionalRemoveUnavailableLabel : "Checkout"}
      </Button>
    </Flex>
  ) : null
})
