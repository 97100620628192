import React, { useMemo } from "react"
import { Box, Text } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

export const CartThresholdUS: React.FC = React.memo(() => {
  const { freeShipping } = useCart()
  const { gwpSettings, message: giftMessage } = useGiftWithPurchase()
  const showGiftMessage = useMemo(
    () => gwpSettings?.giftWithPurchaseEnabled && giftMessage,
    [giftMessage, gwpSettings?.giftWithPurchaseEnabled]
  )

  return showGiftMessage || freeShipping?.message ? (
    <Box textAlign="center">
      <Text variant="text12" pt={2}>
        {showGiftMessage ? giftMessage : freeShipping.message}
      </Text>
    </Box>
  ) : null
})
