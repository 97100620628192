import React, { useMemo } from "react"

import { Box, Flex, FlexProps, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import { useCart } from "@app/hooks/useCart"
import Icon from "@app/components/Icon"
import { useConfigContext } from "@app/providers/config"

export type Props = {
  isCartPage?: boolean
} & FlexProps

export const CartError: React.FC<Props> = ({ ...rest }) => {
  const { store } = useConfigContext()
  const { unavailableItems } = useCart()
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartErrorQuery>(graphql`
    query StaticCartError {
      cart: sanityPageCart {
        additionalUnavailableMessage
      }
    }
  `)

  const style = useMemo(
    () =>
      store?.locationRegion === "US"
        ? {
            py: 1,
            px: 4,
          }
        : {
            borderRadius: "8px",
            py: 2,
            px: 5,
          },
    [store?.locationRegion]
  )

  if (!unavailableItems.length) return null

  return (
    <Flex {...style} bg="primary.errorLight" gap={2} alignItems="start" {...rest}>
      <Box minWidth={5}>
        <Icon name="info" color="primary.error" width={5} fill="transparent" />
      </Box>
      <Text color="primary.error" fontSize={12} lineHeight="20px">
        {cart?.additionalUnavailableMessage}
      </Text>
    </Flex>
  )
}
