import { useCallback } from "react"

import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useCartContext } from "@app/providers/cart"
import { useTrackingContext } from "@app/providers/tracking"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { useShop } from "./useShop"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

export const stringToSha256 = async (inputString: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(inputString)
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("") // convert bytes to hex string
  return hashHex
}

export const formatAnalyticsPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digits
  phoneNumber = phoneNumber.replace(/\D/g, "")

  // Remove leading zeros
  phoneNumber = phoneNumber.replace(/^0+/, "")

  return phoneNumber
}

export const formatAnalyticsCity = (city: string) => {
  // Remove spaces
  city = city.replace(/\s/g, "")

  // Remove non-alphanumeric characters
  city = city.replace(/[^a-zA-Z0-9]/g, "")

  // Lowercase
  city = city.toLowerCase()

  return city
}

export const useAnalytics = () => {
  const { tracked, gtm, latestVersion, colourOptionName, setProductImpressionBatch } = useTrackingContext()
  const {
    helpers: { isBrowser, isDev, decodeShopifyId, formatPrice, capitalise, edgeNormaliser },
  } = useCore()
  const { cart, currencyCode } = useCartContext()
  const { customer } = useCustomerContext()
  const { productNormaliser } = useShopify()
  const { store } = useConfigContext()
  const { shop } = useShop()

  const decorateUrl = (url: string): string => {
    if (isBrowser) {
      // @ts-ignore next-line
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function") {
        // @ts-ignore next-line
        const tracker = ga.getAll()[0]
        // @ts-ignore next-line
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const getVariantOptionValue = (options: any, selectedName: string) =>
    options?.find(({ name }: { name: string }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (gtm) {
        if (isDev) console.log({ ...dataLayer })
        if (!isDev) gtm.dataLayer({ dataLayer })
      }
    },
    [gtm, isDev]
  )

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              path_referrer: isBrowser ? window?.previousPath : document?.referrer,
              store: store.shopifyShopDomain?.split(".")?.[0] || "",
              currency: shop?.currencyCode,
              ...(customer?.id && { customer_id: customer?.id }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              store: store.shopifyShopDomain?.split(".")?.[0] || "",
              currency: shop?.currencyCode,
            }

        const now = new Date()
        const timestampNow = now.getTime()
        const randomInt = Math.floor(Math.random() * 100000000000000)

        const eventID = {
          event: "eventIDGenerated",
          timestampNow: timestampNow,
          randomInt: randomInt,
        }

        pushDataLayer(eventID)
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [tracked, customer, latestVersion, pushDataLayer, isBrowser, store, shop])

  const trackGDPRConsent = useCallback(() => {
    if (tracked) {
      const dataLayer = latestVersion
        ? {
            event: "gdpr_consent",
          }
        : {
            event: "GDPR Consent",
          }
      pushDataLayer(dataLayer)
    }
  }, [tracked, latestVersion, pushDataLayer])

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      if (!product || !product?.shopify?.raw) return

      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)

      if (title) {
        if (latestVersion) {
          const impression = {
            item_id: decodeShopifyId(id, "Product") || product.shopify.id,
            item_name: title,
            item_brand: vendor,
            item_variant: product?.variants?.[0]?.title,
            item_category: productType ?? collections?.[0]?.title,
            item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
            item_list_id: "", // TODO: Define lists
            index,
            quantity: 1,
            price: formatPrice(priceRange?.minVariantPrice?.amount),
          }
          setProductImpressionBatch(prevState => (prevState ? [...prevState, impression] : [impression]))
        } else {
          const dataLayer = {
            event: "productImpression",
            ecommerce: {
              currencyCode: currencyCode,
              impressions: [
                {
                  id: id,
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || productType,
                  price: priceRange?.minVariantPrice?.amount,
                  list: list || "Collection", // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                  position: index,
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [currencyCode, productNormaliser, decodeShopifyId, formatPrice, latestVersion, pushDataLayer, setProductImpressionBatch]
  )

  const trackProductClick = useCallback(
    async (product, variant, index, list = null) => {
      const { collections, id, priceRange, title, vendor, productType } = product
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currencyCode: currencyCode,
                click: {
                  actionField: { list: list || "Collection" }, // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: formatPrice(variant?.priceV2?.amount || (product?.variants && product?.variants?.[0]?.priceV2?.amount)),
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currencyCode: currencyCode,
                detail: {
                  actionField: { list: "Product Page" }, // Product Page
                  products: [
                    {
                      id: decodeShopifyId(id, "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, productNormaliser, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackCartUpdate = useCallback(
    async (type: string, variantId: string, quantity: number, lineitems: CartLine[]) => {
      const selectedLineItem = lineitems?.filter(({ merchandise }) => merchandise?.id === variantId)[0]

      if (selectedLineItem?.merchandise.product.title) {
        const dataLayer = latestVersion
          ? {
              event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(variantId, "ProductVariant"),
                    item_name: selectedLineItem?.merchandise.product.title,
                    item_brand: selectedLineItem?.merchandise.product.vendor,
                    item_variant: getVariantOptionValue(selectedLineItem?.merchandise.selectedOptions, colourOptionName),
                    item_category: selectedLineItem?.merchandise.product.productType,
                    price: formatPrice(
                      selectedLineItem?.cost.compareAtAmountPerQuantity?.amount || selectedLineItem?.cost.amountPerQuantity?.amount
                    ),
                    quantity,
                    discount: formatPrice(
                      selectedLineItem.discountAllocations
                        ?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)
                        ?.toString()
                    ),
                  },
                ],
              },
            }
          : {
              event: ["add", "change"].includes(type) ? "addToCart" : "removeFromCart",
              ecommerce: {
                currencyCode: currencyCode,
                [type]: {
                  products: [
                    {
                      id: decodeShopifyId(variantId, "ProductVariant"),
                      name: selectedLineItem?.merchandise.product.title,
                      brand: selectedLineItem.merchandise.product.vendor,
                      category: selectedLineItem?.merchandise.product.productType,
                      price: formatPrice(
                        selectedLineItem?.cost.compareAtAmountPerQuantity?.amount || selectedLineItem?.cost.amountPerQuantity?.amount
                      ),
                      quantity,
                      variant: getVariantOptionValue(selectedLineItem?.merchandise.selectedOptions, colourOptionName),
                      dimension2: getVariantOptionValue(selectedLineItem?.merchandise.selectedOptions, `Size`),
                      dimension3: selectedLineItem?.merchandise.availableForSale ? `In Stock` : `Out of Stock`,
                      discount: formatPrice(
                        selectedLineItem.discountAllocations
                          ?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)
                          ?.toString()
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackCartView = useCallback(async () => {
    if (cart?.lines.length) {
      if (latestVersion) {
        const dataLayer = {
          event: "view_cart",
          ecommerce: {
            currency: currencyCode,
            items: cart.lines.map(line => ({
              item_id: decodeShopifyId(line.merchandise?.id, "ProductVariant"),
              item_name: line.title,
              item_brand: line.merchandise?.product?.vendor,
              item_variant: getVariantOptionValue(line.merchandise?.selectedOptions, colourOptionName),
              item_category: line.merchandise?.product?.productType,
              price: formatPrice(line.merchandise?.priceV2?.amount),
              quantity: line.quantity,
              discount: formatPrice(
                line.discountAllocations?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)?.toString()
              ),
            })),
          },
        }
        pushDataLayer(dataLayer)
      }
    }
  }, [cart, currencyCode, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer])

  const trackWishlistUpdate = useCallback(
    async (type, product) => {
      if (product?.title) {
        if (latestVersion) {
          const dataLayer = {
            event: ["add"].includes(type) ? "add_to_wishlist" : "remove_from_wishlist",
            ecommerce: {
              currency: currencyCode,
              items: [
                {
                  item_id: decodeShopifyId(product?.selectedVariant?.id, "ProductVariant"),
                  item_name: product?.title,
                  item_brand: product?.vendor,
                  item_variant: getVariantOptionValue(product?.selectedVariant?.selectedOptions, colourOptionName),
                  item_category: product?.productType,
                  price: formatPrice(
                    product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                  ),
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackLogin = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "login",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackSignup = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async term => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackUserIdentity = useCallback(
    async (customer: any) => {
      if (!!customer && customer?.email) {
        let dataLayer = {}

        const defaultAddress = edgeNormaliser(customer?.addresses)?.find((address: any) => address?.id === customer?.defaultAddress?.id)

        const first_name = customer?.firstName?.toLowerCase()
        const last_name = customer?.lastName?.toLowerCase()
        const city = defaultAddress?.city ? formatAnalyticsCity(defaultAddress.city) : null
        const zip = defaultAddress?.zip
        const phone =
          (customer?.phone && formatAnalyticsPhoneNumber(customer.phone)) ||
          (defaultAddress?.phone && formatAnalyticsPhoneNumber(defaultAddress.phone)) ||
          null
        const country_code = defaultAddress?.countryCode?.toLowerCase()

        if (customer?.email) {
          dataLayer = {
            event: "userIdentify",
            email: customer.email,
            em: await stringToSha256(customer.email),
            ...(first_name && {
              first_name: first_name,
              fn: await stringToSha256(first_name),
            }),
            ...(last_name && {
              last_name,
              ln: await stringToSha256(last_name),
            }),
            ...(city && {
              city,
              ct: await stringToSha256(city),
            }),
            ...(zip && {
              zip,
              zp: await stringToSha256(zip),
            }),
            ...(phone && {
              phone,
              ph: await stringToSha256(phone),
            }),
            ...(country_code && {
              country_code,
              country: await stringToSha256(country_code),
            }),
          }
        }
        pushDataLayer(dataLayer)
      }
    },
    [edgeNormaliser, pushDataLayer]
  )

  const trackActiveCartClose = useCallback(
    async (lineItems?: CartLine[]) => {
      if (tracked) {
        const dataLayer = {
          event: "active_cart_close",
          ecommerce: {
            currency: currencyCode,
            items: lineItems?.map((lineitem: any) => {
              const name = []
              if (lineitem?.merchandise?.product?.title) name.push(lineitem?.merchandise?.product?.title)
              if (lineitem?.merchandise?.title) name.push(lineitem?.merchandise?.title)
              return {
                item_id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
                item_name: name?.join(" - "),
                item_brand: lineitem?.merchandise?.product?.vendor,
                item_variant: getVariantOptionValue(lineitem?.selectedVariant?.selectedOptions, colourOptionName),
                item_category: lineitem?.merchandise?.product?.productType,
                price: lineitem?.merchandise?.price?.amount,
                quantity: lineitem?.quantity,
              }
            }),
          },
        }
        pushDataLayer(dataLayer)
      }
    },
    [colourOptionName, currencyCode, decodeShopifyId, pushDataLayer, tracked]
  )

  const trackActiveCartCheckout = useCallback(
    async (lineItems: CartLine[]) => {
      if (tracked) {
        const dataLayer = {
          event: "active_cart_checkout",
          ecommerce: {
            currency: currencyCode,
            items: lineItems?.map((lineitem: any) => {
              const name = []
              if (lineitem?.merchandise?.product?.title) name.push(lineitem?.merchandise?.product?.title)
              if (lineitem?.merchandise?.title) name.push(lineitem?.merchandise?.title)
              return {
                item_id: decodeShopifyId(lineitem?.merchandise?.id, "ProductVariant"),
                item_name: name?.join(" - "),
                item_brand: lineitem?.merchandise?.product?.vendor,
                item_variant: getVariantOptionValue(lineitem?.selectedVariant?.selectedOptions, colourOptionName),
                item_category: lineitem?.merchandise?.product?.productType,
                price: lineitem?.merchandise?.price?.amount,
                quantity: lineitem?.quantity,
              }
            }),
          },
        }
        pushDataLayer(dataLayer)
      }
    },
    [colourOptionName, currencyCode, decodeShopifyId, pushDataLayer, tracked]
  )

  return {
    tracked,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    trackUserIdentity,
    decorateUrl,
    trackGDPRConsent,
    trackActiveCartClose,
    trackActiveCartCheckout,
  }
}
