import React from "react"
import { BoxProps } from "@chakra-ui/react"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"
import { CartProgressCard } from "@app/components/Cart/CartProgressCard"

export const GiftProgressCard: React.FC<BoxProps> = React.memo(({ ...props }) => {
  const { gwpSettings, percentage, message } = useGiftWithPurchase()

  return gwpSettings?.giftWithPurchaseEnabled && message ? (
    <CartProgressCard iconName="gift" text={message as string} progress={percentage} {...props} />
  ) : null
})
