import React, { useContext, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

type GlobalProps = GatsbyTypes.SanityTemplateGlobal &
  Pick<
    GatsbyTypes.SanityTemplateProduct,
    "additionalStockOut" | "additionalAddToCart" | "additionalAddToCartProduct" | "additionalAddToCartLoading"
  >

export const GlobalContext = React.createContext<GlobalProps | undefined>(undefined)

export const GlobalProvider: React.FC = ({ children }) => {
  const { global, product } = useStaticQuery(graphql`
    query StaticGlobalTemplate {
      global: sanityTemplateGlobal {
        title
        storesText
        accountText
        wishlistText
        additionalClose
        additionalBack
        additionalBundleAccordion
        additionalSubscribed
      }
      product: sanityTemplateProduct {
        additionalStockOut
        additionalAddToCart
        additionalAddToCartProduct
        additionalAddToCartLoading
        additionalInStoreOnly
      }
    }
  `)

  const contextValue = useMemo<GlobalProps>(() => {
    return {
      ...global,
      ...product,
    }
  }, [global, product])

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}

export const useGlobalContext = (): GlobalProps => ({ ...useContext(GlobalContext) }) as GlobalProps
