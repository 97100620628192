import React, { useEffect, useState } from "react"
import { Box, FormControl, FormErrorMessage, Input, Text, FormLabel, Button, Heading } from "@chakra-ui/react"
import { useNewsletter } from "@app/hooks/useNewsletter"
import { useInput } from "@app/hooks/useInput"

import Turnstile from "react-turnstile"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useFunctions } from "@app/hooks/useFunctions"
import { useTurnstile } from "@app/hooks/useTurnstile"
import { useConfigContext } from "@app/providers/config"
import { formatPhoneNumber } from "@app/hooks/useCustomer"
import Link from "../Link"

import AccountFormError from "@app/components/Account/AccountFormError"

import type { Location } from "@root/types/global"
type Props = {
  success: boolean
  setSuccess: (value: boolean) => void
  headSize: string
  location: Location
}

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const SubscribeFormDetailed: React.FC<Props> = ({ success, setSuccess, headSize }) => {
  const {
    store: { locationLookupUrl, emarsysSignupFormID },
  } = useConfigContext()
  const { callFunction, errors: apiErrors } = useFunctions()
  const { newsletter, handleClose } = useNewsletter(location)
  const {
    turnstileError,
    isDisabled,
    turnstileToken,
    turnstile_key,
    widgetId,
    setIsDisabled,
    setTurnstileError,
    setTurnstileToken,
    setWidgetId,
  } = useTurnstile()
  const { trackUserIdentity } = useAnalytics()
  const [formError, setFormError] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const { value: firstName, bind: bindFirstName } = useInput({})
  const { value: lastName, bind: bindLastName } = useInput({})
  const { value: email, bind: bindEmail } = useInput({ trim: true })
  const { value: phone, bind: bindPhone } = useInput({})

  const error = formError || turnstileError || apiErrors?.[0]
  const successText = newsletter?.additionalSuccess || "Thank you for subscribing!"
  const successText2 = newsletter?.additionalSuccess1 || ""
  const termComditionTitle = newsletter?.TCtitle
  const termComditionLink = newsletter?.TClink

  useEffect(() => {
    if (!!apiErrors?.length || turnstileError) {
      setIsDisabled(true)
      setIsLoading(false)
      if (widgetId) window?.turnstile?.reset(widgetId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrors, turnstileError])

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    let formattedPhoneNumber: string

    try {
      const { country_calling_code } = await fetch(locationLookupUrl).then(res => res.json())
      formattedPhoneNumber = formatPhoneNumber(phone, country_calling_code)
    } catch {
      formattedPhoneNumber = phone
    }

    if (!email || (email && !emailRegex.test(email))) {
      setFormError(`An email address must be provided.`)
    } else {
      const response = await callFunction("emarsys-contact-form", {
        formID: emarsysSignupFormID,
        email,
        firstName,
        lastName,
        formattedPhoneNumber,
        ["cf-turnstile-response"]: turnstileToken,
      })

      if (response.status === "success") {
        setSuccess(true)
        trackUserIdentity({ email })
      }
    }

    setIsLoading(false)
  }

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      w="full"
      mt={3}
      textAlign="left"
      sx={{
        "& label span": {
          ml: 0,
          color: "brand.black",
        },
      }}
    >
      {!success ? (
        <>
          <FormControl mb={3} id="firstName" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              First Name
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="First Name" name="firstName" type="text" {...bindFirstName} />
          </FormControl>

          <FormControl mb={3} id="lastName" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              Last Name
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Last Name" name="lastName" type="text" {...bindLastName} />
          </FormControl>

          <FormControl mb={3} id="email" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              Email
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Email" name="email" type="email" {...bindEmail} />
            <FormErrorMessage>Please enter email address.</FormErrorMessage>
          </FormControl>

          <FormControl mb={3} id="phone">
            <FormLabel fontSize={12} lineHeight="20px">
              Phone Number
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Phone Number" name="phone" type="tel" {...bindPhone} />
          </FormControl>

          <Text variant="text12" m="auto" mt={2} mb={4}>
            {termComditionTitle}
            {termComditionLink?.pageLink && (
              <Link to={"/" + termComditionLink?.pageLink?.handle?.current} external={termComditionLink?.external}>
                {termComditionLink?.title}
              </Link>
            )}
          </Text>

          <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            variant="fillSmall"
            type="submit"
            aria-label="Submit"
            mt={5}
            px={9}
            mx="auto"
            display="flex"
          >
            Join the fun!
          </Button>
          {turnstile_key ? (
            <Turnstile
              sitekey={turnstile_key}
              onVerify={token => {
                setTurnstileToken(token)
                setIsDisabled(false)
                setTurnstileError(null)
              }}
              onLoad={widgetId => {
                setWidgetId(widgetId)
                setIsDisabled(true)
              }}
              onExpire={() => {
                setIsDisabled(true)
                if (widgetId) window?.turnstile?.reset(widgetId)
              }}
              onError={error => {
                setIsDisabled(true)
                setTurnstileError(`Verification failed (${error}). Please refresh the page and try again.`)
              }}
            />
          ) : null}
          {error ? <AccountFormError message={error} /> : null}
        </>
      ) : (
        <>
          <Heading as="p" variant={headSize} lineHeight="28px" p="15px 0" letterSpacing="0.03em" fontWeight="bold" textAlign="center">
            {successText}
            <br />
            {successText2}
          </Heading>
          <Button variant="fillSmall" onClick={handleClose} mt={5} px={9} mx="auto" display="flex">
            Start Shopping
          </Button>
        </>
      )}
    </Box>
  )
}

export default React.memo(SubscribeFormDetailed)
