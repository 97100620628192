import React from "react"
import { BoxProps } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { CartProgressCard } from "@app/components/Cart/CartProgressCard"

export const FreeShippingProgressCard: React.FC<BoxProps> = React.memo(({ ...props }) => {
  const { freeShipping } = useCart()

  return freeShipping.threshold >= 0 && freeShipping.message ? (
    <CartProgressCard iconName="truck" text={freeShipping.message} progress={freeShipping.percentage} {...props} />
  ) : null
})
