import React, { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useShopify } from "@app/hooks/useShopify"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  drawer?: boolean
}

export const CartSummary: React.FC<Props> = React.memo(({ drawer = false }) => {
  const { cart, currencyCode, linesCount, warnings } = useCartContext()
  const { formatMoney } = useShopify()
  const { payments } = useStaticQuery<GatsbyTypes.StaticPaymentsQuery>(graphql`
    query StaticPayments {
      payments: sanitySettingPayments {
        enabled {
          value
        }
        checkoutLabel
      }
    }
  `)

  const methods = useMemo(
    () =>
      payments?.enabled?.reduce(
        (all: any, method: any, index: number) =>
          !index ? method?.value : index === payments?.enabled?.length - 1 ? `${all} or ${method?.value}` : `${all}, ${method?.value}`,
        ""
      ),
    [payments?.enabled]
  )

  const cartSubtotal = useMemo(
    () => cart?.cost.subtotalAmount.amount && formatMoney(Number(cart?.cost.subtotalAmount.amount)),
    [formatMoney, cart?.cost.subtotalAmount]
  )

  return (
    <Box w="100%" px={5} py={4} borderTop={drawer ? "1px solid" : "unset"} borderTopColor="brand.grayDisabled">
      {linesCount > 0 && (
        <Box>
          {warnings?.length
            ? warnings.map(({ message, target }) => {
                return (
                  <Flex key={target} w="100%" alignItems="center" justifyContent="space-between">
                    <Text variant="text18">{message}</Text>
                  </Flex>
                )
              })
            : null}
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Text variant="text18" fontWeight={500}>
              Subtotal{" "}
              <Text variant="text10" fontSize={10} display="inline-block" ml={2}>
                ({currencyCode})
              </Text>
            </Text>
            <Text variant="text18" fontWeight={500}>
              {cartSubtotal}
            </Text>
          </Flex>
          <Flex alignItems="center" mt={1}>
            <Text variant="text12">{payments?.checkoutLabel?.replace("{methods}", methods)}</Text>
          </Flex>
        </Box>
      )}
    </Box>
  )
})
