module.exports = {
  id: "allkindsau",
  title: "Allkinds",
  description:
    "Say hello to awesome: all kinds of bath, body, haircare, skincare & fun stuff made just for kids. And with zero gross stuff.",
  url: "allkinds.com/au",
  subfolder: process.env.GATSBY_ACTIVE_ENV === "production" ? "/au" : "",

  locationCurrencyCode: "AUD",
  locationRegion: "AU",
  locationRegionFullName: "Australian",
  locationCountries: "AU,NZ",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/?key=Di68RWc6asPYru3MCgBD5bAZIMWaQwIFirSSKxUNjtwx9xDVnU",

  shopifyPlus: false,
  shopifyApiVersion: "2025-01",
  shopifyShopName: "allkinds-prd",
  shopifyShopDomain: "allkinds-prd.myshopify.com",
  shopifyShopDomainOther: "allkinds-us.myshopify.com",
  shopifyCheckoutUrl: "checkout-au.allkinds.com",
  shopifyStorefrontToken: "5ccb0d9202557d8a1b9a577ad32e7035",
  shopifyStorefrontTokenOther: "e42df8824d2a8a12f4520a2ecf1cd115",
  shopifyMetafieldIdStoreSync: "17216169803963",
  minimumOrderValue: 0,

  googleSheetsSpreadsheetId: "1VhEb12HgZJ9bOY2GIZ1Xaa9ebj6z4WuZWOXPVIlvvds",
  googleSheetsSheetId: "AU",

  sanityProjectId: "3oef59p4",
  sanityDataset: "production-au",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skBoBI9fgEuAQxyYIWmwR1UaOXiCCkSLD69ce0lIIQazPjGb81Kb87Am9yIo8thtIFJjcLMY6IPwkU3DqlpPg98JkQ75CbhNfrKTvMRZi3dVbaKhAK2g6IkIxu5uzOlsaycOiBKBQIb39QN7yttUYYA12yGL31xhdYQ9OVNwRr2vjfA41fMJ",

  emarsysSignupFormID: "2419",
  emarsysCompetitionFormID: "2872",
  emarsysNewsletterFormID: "1464",

  emarsysAnalyticsId: "1BA781E6AE47E4CF",
  emarsysSkinQuizFormID: "2420",
  emarsysAgeRangeFormID: "28673",
  emarsysCartFieldId: "31907",
  giftCardType: "Gift Card",
  tolstoyAppKey: "321b64bb-f483-4f59-8dc1-103ecf11d1b4",

  mapboxAccessToken: "pk.eyJ1IjoiYnJhbmRiYW5rIiwiYSI6ImNsOTNkYnVsOTAxZmozdnIyNDN0ZjhzanEifQ.rRjLiL_nVNUjPcGxkSfHKA",
  flowProxyEndpoint: "https://api.brandbank.com.au",
  stockBrand: "AKAU",
  stockServiceUrl: "https://api.brandbank.com.au/inventory/v2/findinstore",
  givexBrand: "allkinds-au",
  givexEndpoint: "https://givex-giftcards.azurewebsites.net",

  wishlistPlusStorefrontEndpoint: "https://swymstore-v3premium-01.swymrelay.com/api/",
  wishlistPlusAdminEndpoint: "https://swymstore-v3premium-01.swymrelay.com/storeadmin/v3/user/",
  wishlistPlusApiKey: "gyoCZ8aVG7pHtdotPNtcUN3bdeZ4drP-yHsRPK2VFGpgVUAjcZk8m1oAoho_rhOGhZI917K4Fi-PFiLwZRKnAA",
  wishlistPlusPid: "lYrJt0ykjQe7HZz224SVbiIvHqdQZIbWKpCcB5j4GSI=",
  yotpoAppKey: "QElALcEz8lNaqddyszTyvv29t1loDuq4jWIbu6bb",
}
